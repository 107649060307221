import {createMuiTheme} from "@material-ui/core";

const FontSize = '1.4rem';
const ColorSecondary = '#174E59';
const BorderColor = '#EAEAEA';
const HStyle: any = ({
  color: '#174E59',
  fontWeight: 500,
  textTransform: 'uppercase'
});

export default createMuiTheme({
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontWeightRegular: 400,
    h1: {...HStyle},
    h2: {...HStyle},
    h3: {...HStyle},
    h4: {
      ...HStyle,
      fontSize: '2.4rem',
      "&$secondary": {
        color: ColorSecondary,
      }
    },
    h5: {
      ...HStyle,
      fontSize: '2rem',
      textTransform: 'capitalize'
    },
    body1: {
      fontSize: '1.4rem'
    }
  },
  palette: {
    text: {
      primary: '#202020'
    },
    primary: {
      main: '#5FAF59',
      contrastText: '#fff'
    },
    secondary: {
      main: ColorSecondary,
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiTypography: {
      colorSecondary: {
        color: ColorSecondary
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderColor: BorderColor,
        borderWidth: 2
      }
    },
    MuiButton: {
      root: {
        color: ColorSecondary
      }
    },
    // GRID
    MuiGrid: {
      item: {
        width: '100%',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
      },
      container: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      }
    },
    // DIALOG
    MuiDialogTitle: {
      root: {
        textTransform: 'none'
      }
    },
    MuiDialog: {
      paper: {
        overflowY: 'inherit'
      }
    },
    MuiFormControl: {
      root: {
        margin: '0 0 1.5rem'
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, .4rem)',
        fontSize: '1.2rem'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: FontSize,
        color: '#888D8F',
        "&$focused": {
          color: "#888D8F"
        }
      }
    },
    MuiInputAdornment: {
      positionStart: {
        color: BorderColor
      }
    },
    MuiInput: {
      root: {
        fontSize: FontSize,
      },
      underline: {
        '&:before': {
          borderBottomColor: BorderColor
        }
      },
    },
    MuiSnackbarContent: {
      message: {
        fontSize: FontSize
      }
    },
    // @ts-ignore
    MuiPickerDTTabs: {
      tabs: {
        color: '#fff'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: ColorSecondary,
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: ColorSecondary
      }
    },
    MuiPickersModal: {
      withAdditionalAction: {
        '& > .MuiButton-textPrimary': {
            color: ColorSecondary
        }
      }
    },
    // MuiPickersModal: {
    //   withAdditionalAction: {
    //     '& > button': {
    //       color: '#31586d'
    //     }
    //   }
    // }
  }
})
