import React, {FC} from 'react';
// style
import theme from "styles/theme"
import "AurionCR/styles/_main.scss"
import "styles/_project-theme.scss"
// store
import {Provider} from "react-redux";
import store from "AurionCR/store"
import storeConfig from "store/redux"
// layout
import Page404 from 'AurionCR/pages/Page404'
import Layout from 'pages/Layout'
// router
import routesConfig from 'configs/routes';
// notify
import {SnackbarProvider} from "notistack";
// l18n
import {
  I18nContextProvider,
  AuthContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
  // AxiosInterceptors,
  ResponsiveProvider,
  RTLThemeProvider
} from "AurionCR/components";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {AxiosInterceptors} from 'components/axios-interceptors'
import DateFnsUtils from '@date-io/date-fns';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

declare global {
  interface Window {
    authCookieName_: any;
    authRefreshCookieName_: string;
    authDateCookieName_: string;
  }
}
/**
 * TODO: TypeScript not apply yet!...
 * TODO: TypeScript not apply yet!...
 * TODO: TypeScript not apply yet!...
 */

const App: FC = () => {
  window.authCookieName_ = 'tiktak_auth_crm';
  window.authRefreshCookieName_ = 'tiktak_auth_refresh_crm';
  window.authDateCookieName_ = 'tiktak_auth_expires_crm';
  return <ResponsiveProvider>
    <Provider store={store(storeConfig)}>
      <AxiosInterceptors/>
      <AuthContextProvider>
        <DndProvider backend={HTML5Backend}>
          <I18nContextProvider apiLabelsFilter='labelSource.key == "crm"'>
            <RTLThemeProvider theme={theme}>
              <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Notifier/>
                <LoadingOverlayProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Routes
                      layout={Layout}
                      routes={routesConfig}
                      page404={Page404}
                    />
                  </MuiPickersUtilsProvider>
                </LoadingOverlayProvider>
              </SnackbarProvider>
            </RTLThemeProvider>
          </I18nContextProvider>
        </DndProvider>
      </AuthContextProvider>
    </Provider>
  </ResponsiveProvider>;
};

export default App;
