import React, {FC, createContext, useContext, ReactElement, useState, useCallback, useEffect} from "react";
import {useAuth} from "./auth";
import {useDispatch} from "react-redux";
import {notifyRequestResult} from "../store/modules/notify";

const initState = {
  users: {},
  connected: false,
  status: 'Trying to connect ...',
  ws: null,
  log: []
};
export const WSContext = createContext({...initState});
export const useWS = () => {
  const context: any = useContext(WSContext);
  const dispatch = useDispatch();
  return {
    sendCommand(users: any[] = [], msg: any, notification: boolean = true) {
      if (context.connected) {
        let clients = users.filter((id: any) => context.users[id]);
        if (clients.length === users.length) {
          context.log.unshift(msg);
          context.log = context.log.slice(0, 50);
          context.ws.send(JSON.stringify({clients: clients, msg: msg}));
          if (notification) dispatch(notifyRequestResult('Send Command'));
          return false
        } else {
          if (notification) dispatch(notifyRequestResult('One is user offline, please check your selection', 'error'));
        }
      } else {
        if (notification) dispatch(notifyRequestResult('Send Command'));
      }
    },
    ...context
  }
};
export const WebSocketProvider: FC<{ children: ReactElement }> = ({children}) => {
  const auth = useAuth();
  const [state, setState] = useState({...initState});
  const [url] = useState('wss://db-engine-service.azurewebsites.net:443');
  const [timeOutConnect, seTimeOutConnect] = useState<any | null>(null);
  const [isAllowReconnected, setIsAllowReconnected] = useState(true);

  // status
  const updateState = useCallback((data: any) => {
    setState((state: any) => ({...state, ...data}));
  }, [setState]);

  // WS
  const WSDisconnect = () => {
    setIsAllowReconnected(false);
    updateState({
      connected: false,
      status: `Lost/error connect to: ${url}, reconnect after 5s...`
    });
    if (timeOutConnect) {
      clearTimeout(timeOutConnect);
      seTimeOutConnect(null);
    }
    if (state.ws) {
      // @ts-ignore
      state.ws.close();
      updateState({ws: null});
    }
  };
  const WSError = (err: any) => {
    console.error(err);
    WSDisconnect();
    if (isAllowReconnected) seTimeOutConnect(setTimeout(WSInit, 5000));
  };
  const WSInit = () => {
    if (auth.user && !state.ws) {
      updateState({status: `Start connected to:\n ${url}`});
      const ws_ = new WebSocket(url);
      updateState({ws: ws_});
      ws_.onopen = () => {
        // update status
        updateState({
          status: `Is connected to:\n ${url}`,
          connected: true
        });
        // reg on WSS
        ws_.send(JSON.stringify({
          type: 'reg',
          admin: {
            // @ts-ignore
            customer: auth.user.customerID
          }
        }))
      };
      ws_.onmessage = (ev: any) => {
        const data = (ev.data) ? JSON.parse(ev.data) : '';
        if (data) {
          setState((state: any) => {
            state.log.unshift(data);
            return {...state, log: state.log.slice(0, 50)}
          });
          if (data.type === 'clients') {
            setState((state: any) => {
              return {
                ...state,
                users: {
                  ...state.users,
                  ...data.clients.reduce((result: any, item: any) => {
                    result[item.id] = {
                      connected: item.isConnected,
                      flows: item.flows
                    };
                    return result;
                  }, {})
                }
              }
            });
          } else if (data.type === 'update') {
            setState((state: any) => {
              return {
                ...state,
                users: {
                  ...state.users,
                  [data.id]: {
                    connected: data.isConnected,
                    flows: data.flows
                  }
                }
              }
            });
          }
        }
      };
      ws_.onclose = WSError;
      ws_.onerror = WSError;
    }
  };

  // init
  useEffect(() => {
    WSInit();
    return () => {
      WSDisconnect();
    }
  }, [auth.user]);
  return (
    <WSContext.Provider value={{...state}}>
      {children}
    </WSContext.Provider>
  );
};
