import React, {memo, useMemo} from "react";
import {Tooltip} from "@material-ui/core";
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import {validateURL} from "./form"
import {getImageSrc} from "./helpers";

export interface IImageTooltip {
  size?: string; // 36px
  round?: string; // 50%
  placement?: string; // bottom
  className?: string
}

export const ImageTooltip = memo((
  {
    config,
    src,
    title = ''
  }: {
    config?: IImageTooltip,
    src?: string,
    title?: string
  }) => {
  const src_ = useMemo(() => getImageSrc(src), [src]);
  const settings = useMemo(() => ({
    size: '40px',
    round: '50%',
    placement: 'bottom',
    className: '',
    ...config || {}
  }), [config]);
  if (!src) {
    return <div className={'image-tooltip'}>
      <div className="no-image"
           style={{
             width: settings.size,
             height: settings.size,
             borderRadius: settings.round
           }}>
        <ImageSearchIcon/>
      </div>
      {title?.length > 0 && <span>{title}</span>}
    </div>
  } else {
    return <div className={'image-tooltip'}>
      <Tooltip
        // @ts-ignore
        placement={settings.placement}
        title={
          <div
            className={`image-tooltip-content ${settings.className}`}
            style={{maxWidth: '300px'}}
          >
            <img
              src={src_}
              style={{display: 'block', width: '100%'}}/>
            {title?.length > 0 && <strong>{title}</strong>}
          </div>
        }>
        <div className={`avatar ${settings.className}`} style={{
          backgroundImage: `url("${src_}")`,
          width: settings.size,
          height: settings.size,
          borderRadius: settings.round
        }}/>
      </Tooltip>
      {title}
    </div>
  }
});

export default ImageTooltip;
