import {useState, useEffect} from 'react';
import {format, parse} from "date-fns";
import axios, {AxiosRequestConfig} from "axios"
import {validateURL} from "./form";

export const baseURL = `${process.env.REACT_APP_API_SERVER || ''}/api/`;
/**
 * api settings
 */
export const prepareRequest = <T extends { [x: string]: (any | null) } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      // @ts-ignore
      acc[key] = data[key] === '' ? null : data[key]
      return acc
    }, data)
}
const patchDotNet5 = (conf: AxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object
      .entries(prepareRequest(conf.data))
      .filter(([, value]) => value !== undefined)
      .map(([path, value]) => {
        return {path, value}
      })
  }
  return conf
}
export const api = axios.create({baseURL: baseURL});
export const apiStatic = axios.create({baseURL: baseURL});
export const apiStaticCanceled = (config: AxiosRequestConfig & { _cancelID: string }) => {
  if (config._cancelID) config.headers = {...config.headers || {}, _cancelID: config._cancelID};
  return axios({...config, baseURL: baseURL});
};
export const apiCatch = (error: any, callback: any) => {
  if (!axios.isCancel(error)) callback(error);
};
[axios, api, apiStatic].forEach((instance) => {
  instance.interceptors.request.use(patchDotNet5)
});
// cookie
export const getCookies = (name: string) => {
  if (!name) return undefined;
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined
};
export const setCookies = (name: string, data = '', expires = '', domain = '') => {
  if (data) {
    document.cookie = `${name}=${encodeURIComponent(data)};${expires ? ` expires=${new Date(expires).toUTCString()};` : ''} secure; domain=${domain ? domain : window.location.hostname}; samesite=lax; path=/;`;
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};
/**
 * randoms
 */
export const getNewKey = () => new Date().getTime() + Math.random();
export const getRandomString = (length = 10, chars = 'aA') => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};
/**
 * parsers requests handlers
 */
export const requestError = (error: any, defaultMessage = 'error') => {
  let message;
  if (error) {
    if (error.response) {
      if (typeof error.response === 'object') {
        if (typeof error.response.data === 'object') {
          message = error.response.data.message || undefined;
        } else if (typeof error.response.data === 'string') {
          message = error.response.data;
        }
      } else if (typeof error.response === 'string') {
        message = error.response;
      }
    }
  }
  return message || defaultMessage;
};
export const requestSuccess = (response: any, defaultMessage = 'success') => {
  let message;
  if (response) {
    if (typeof response === 'object') {
      if (typeof response.data === 'object') {
        message = response.data.message || undefined;
      } else if (typeof response.data === 'string') {
        message = response.data;
      }
    } else if (typeof response === 'string') {
      message = response;
    }
  }
  return message || defaultMessage;
};
/**
 * es6 templates
 */
export const es6Run = (data: any, field: string) => {
  return data[field] !== undefined ? data[field] : checkEs6AndRun(field, data)
};
export const checkES6Template = (value: any) => {
  return typeof value === 'string' && value.indexOf('${') !== -1
};
export const checkEs6AndRun = (val: string | object, data: any = null) => {
  if (checkES6Template(val)) {
    // eslint-disable-next-line
    let func = new Function('data', `return \`${val}\``);
    return func(data);
  } else if (typeof val === 'function') {
    return val(data)
  } else {
    return val
  }
};
export const checkJsTemplate = (object: any, data: any = null) => {
  return (object && typeof object === 'function') ? object(data) : object || data
};
export const parseJsTemplate = (object: any) => {
  for (let key in object) {
    if (object[key]) {
      let val = object[key]
      if (typeof val === 'string' && object[key].indexOf('${') !== -1) {
        // eslint-disable-next-line
        object[key] = new Function('data', `return \`${object[key]}\``)
      } else if (typeof object[key] === 'object' && Object.keys(object[key]).length) {
        parseJsTemplate(object[key])
      }
    }
  }
  return object
};
export const regExpFieldAS = new RegExp('(\\sas\\s)(.+)', 'g');
// locale
export const getWindowLanguage = () => {
  if (window.navigator.languages) {
    return window.navigator.languages[0];
  } else {
    // @ts-ignore
    return window.navigator.userLanguage || window.navigator.language;
  }
};
// dates
export const convertDateUtcToLocal = (date: Date | undefined | null) => {
  if (date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  } else {
    return date;
  }
};
export const convertDateLocalToUtc = (date: Date | undefined | null) => {
  if (date) {
    return new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    ));
  } else {
    return date;
  }
};
export const convertDateToRDateTime = (date: Date | undefined | null) => {
  if (date) {
    return format(date, 'yyyy,MM,dd,HH,mm,ss')
  } else {
    return date;
  }
};
export const convertRDateTimeToDate = (date: string | undefined | null) => {
  if (date) {
    return parse(date, 'yyyy,MM,dd,HH,mm,ss', new Date())
  } else {
    return date;
  }
};
// images
export const getImageSrc = (src: any) => {
  if (src && validateURL(src) && src.indexOf('data:image/') === -1) {
    return `data:image/png;base64,${src}`;
  }
  return src;
};
// Youtube code
export const getYTCode = (url: any) => {
  if (url) {
    if (url.indexOf('v=') !== -1) {
      return url.slice(url.lastIndexOf('v=') + 2);
    } else if (url.indexOf('http') !== -1) {
      return url.slice(url.lastIndexOf('/') + 1);
    } else {
      return url;
    }
  } else {
    return ''
  }
};
// Interval
export const useInterval = (handler: any, interval: number) => {
  const [intervalId, setIntervalId] = useState<any>();
  useEffect(() => {
    const id = setInterval(handler, interval);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);
  return (): any => clearInterval(intervalId);
};
export const useTimeout = (handler: any, interval: number) => {
  const [intervalId, setIntervalId] = useState<any>();
  useEffect(() => {
    const id = setTimeout(handler, interval);
    setIntervalId(id);
    return () => clearTimeout(id);
  }, []);
  return (): any => clearInterval(intervalId);
};

export interface ITranslateBySource {
  map: any,
  loading: boolean,
  key: string
}

export const translateBySource = ({map, loading, key}: ITranslateBySource) => {
  if (loading) return ' '
  const translate = map[key]
  if (!translate) {
    console.warn('label', key, 'not found');
    return key;
  }
  return translate.title
}
