import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import JoditEditor from "jodit-react";
import {apiStatic, checkEs6AndRun, requestError} from "../helpers";
import {InputLabel} from "@material-ui/core";
import {Loading} from "../loading";
import {validateURL} from "./heplers";
import {notifyRequestResult} from "../../store/modules/notify";
import {useDispatch} from "react-redux";

export const JoditEditorOptions = () => ({
  buttons: 'bold,italic,underline,|,ul,ol,outdent,indent,fontsize,paragraph,video,image,file,table,link,|,align,undo,redo,selectall,cut,copy,paste,|,fullsize,print',
  toolbarSticky: false,
  uploader: {insertImageAsBase64URI: true},
  height: '20rem'
  // events: {
  //   afterRemoveNode: (node: any) => {
  //     console.log(node);
  //   }
  // }
});

export const Editor = memo((
  {
    // api
    apiPath, // API to path model where used Editor, example - 'SiteParameters/Patch/${data.id}'
    apiSet = 'MediaUploads/UploadHtmlToCloud',
    apiGet = 'MediaUploads/GetHtmlFromCloud?fileName=${data}',
    // file name
    fileNameFromFieldModel = 'id', // field, get from main model
    // form fields
    name,
    label,
    value,
    onChange,
    error,
    disabled = false,
    // config
    config
  }: {
    apiPath: string;
    apiSet?: string;
    apiGet?: string;

    fileNameFromFieldModel?: string;

    name?: string;
    label?: string;
    value?: any;
    onChange?: (e: any) => void;
    error?: any;
    disabled?: boolean;

    config?: any;
  }) => {
  const dispatch = useDispatch();
  const editor = useRef(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const config_ = useMemo(() => {
    return {
      buttons: 'bold,italic,underline,|,ul,ol,outdent,indent,|,align,|,fontsize,paragraph,video,image,table,link,|,undo,redo,|,fullsize,print',
      toolbarAdaptive: false,
      toolbarSticky: false,
      uploader: {insertImageAsBase64URI: true},
      height: '20rem',
      cleanHTML: {denyTags: 'script iframe'},
      disabled,
      ...(config || {}),
    };
  }, [disabled, config]);
  const loadData = useCallback((value) => {
    setLoading(true);
    apiStatic
      .get(checkEs6AndRun(apiGet, value))
      .then(response => {
        setLoading(false);
        setContent(response.data);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        dispatch(notifyRequestResult(requestError(error), 'error'));
      })
  }, [setLoading, apiGet, setContent]);

  useEffect(() => {
    if (value) {
      if (typeof value === 'string' && !validateURL(value)) {
        loadData(value);
      } else {
        setContent(value.value)
      }
    } else {
      setContent('')
    }
  }, [value, setContent]);

  // callbacks
  const onChangeData = useCallback((value) => {
    if (onChange) {
      onChange({
        target: {
          value: {
            mixin_: {
              method: 'post',
              url: apiSet,
              data: {
                filePath: 'HtmlParts',
                htmlContent: value
              }
            },
            type_: 'editor',
            apiPath, name, value, fileNameFromFieldModel
          }
        }
      });
    }
  }, [onChange, apiSet, apiPath, fileNameFromFieldModel, name]);

  return <div
    className={`editor-wrapper${label ? ' label' : ''}`}>
    {label &&
    <InputLabel
      shrink
      htmlFor="code-input"
      error={Boolean(error)}
    >
      {label}
    </InputLabel>
    }
    <JoditEditor
      ref={editor}
      value={content}
      config={config_}
      onBlur={onChangeData}
      // onChange={newContent => {
      // console.log('onChange', newContent);
      // }}
    />
    {Boolean(error) && <p className="error">{error.message || ''}</p>}
    <Loading active={loading}/>
  </div>
});

export default Editor;
