import React, {memo} from "react";
import {Button} from "@material-ui/core";
import {useI18n} from "../../i18";

const Controls = memo((
  {
    controls,
    data,
    id,
    onTasks,
    scroll,
    rowControls
  }: {
    controls: any;
    data: any;
    id: any;
    onTasks: (tasks: any[]) => void;
    scroll: { x: number, y: number, '-x': number };
    rowControls?: any;
  }
) => {
  const {t} = useI18n();

  if (controls && !controls.length && !rowControls) return null;
  return (
    <td
      className="data-grid-col-controls"
      style={{transform: `translateX(${scroll['-x']}px)`}}
    >
      <ul className="data-grid-row-controls">
        {Boolean(rowControls) && rowControls(data)}
        {controls && controls.map((control: any) => {
          switch (control.type) {
            case 'clone':
              return <li key={control.type}>
                <Button
                  className="clone"
                  onClick={() => onTasks([['cloneData', data]])}
                  disabled={control.disabled}
                  color="default"
                >
                  {t(control.title)}
                </Button>
              </li>;
            case 'edit':
              return <li key={control.type}>
                <Button
                  className="edit"
                  onClick={() => onTasks([['onHandle', {type: 'edit', id: id, data}]])}
                  disabled={control.disabled}
                  color="default"
                >
                  {t(control.title)}
                </Button>
              </li>;
            case 'delete':
              return <li key={control.type}>
                <Button
                  className="delete"
                  onClick={() => onTasks([['deleteData', data]])}
                  disabled={control.disabled}
                  color="primary"
                >
                  {t(control.title)}
                </Button>
              </li>;
            default:
              return null
          }
        })
        }
      </ul>
    </td>
  )
});

export default Controls;
