import {memo, useEffect} from "react";
import {useDispatch} from "react-redux";
import axios, {AxiosRequestConfig, AxiosError, AxiosInstance} from "axios"
import {api, apiStatic, getCookies, setCookies, requestError} from "AurionCR/components/helpers";
import {authFail} from "AurionCR/store/modules/auth";

// TODO: canceled
const processing: any = {};
const getProcessingKey = (config: AxiosRequestConfig) => {
  if (config && config.headers && config.headers._cancelID) {
    return config.headers._cancelID;
  } else {
    return null;
  }
};
// TODO: Token/RefreshToken
let isRefreshing = false;
let refreshSubscribers: any[] = [];
const updateDefaultsHeader = (token?: string) => {
  if (token) {
    [axios, apiStatic, api].forEach(item => {
      item.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    })
  } else {
    [axios, apiStatic, api].forEach(item => {
      delete item.defaults.headers.common['Authorization'];
    })
  }
};
const onRefreshed = (token: string) => {
  refreshSubscribers.map(cb => cb(token));
};
const subscribeTokenRefresh = (cb: any) => {
  refreshSubscribers.push(cb);
};
const updateTokenAll = ({token = '', refreshToken = '', expires = ''} = {}) => {
  // update cookie
  setCookies(window.authCookieName_, token, expires);
  // setCookies(window.authRefreshCookieName_, refreshToken, expires);
  setCookies(window.authDateCookieName_, expires, expires);
  // update header
  updateDefaultsHeader(token);
};
const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const token = getCookies(window.authCookieName_);
    const refreshToken = getCookies(window.authRefreshCookieName_);
    const expires = getCookies(window.authDateCookieName_);
    if (token && refreshToken && expires) {
      apiStatic
        .post('Accounts/RefreshToken', {token, refreshToken})
        .then(response => {
          const {token, refreshToken} = response.data;
          updateTokenAll({token, refreshToken, expires});
          // run rest requests
          isRefreshing = false;
          onRefreshed(token);
          resolve();
        })
        .catch(reject);
    } else {
      reject()
    }
  });
};
const authFailed = (error: AxiosError | null, dispatch: any) => {
  updateTokenAll();
  // clear rest requests
  refreshSubscribers = [];
  // go to login page
  dispatch(authFail(error ? requestError(error) : null));

  return new Promise(() => {
  });
};
const addSubscribableInstance = (instance: AxiosInstance, dispatch: any) => {
  instance.interceptors.response.use(
    response => response,
    async error => {
      const {config: originalReq, response: {status, headers}} = error;
      if (status === 401) {
        // if (headers['token-expired']) {
        //   if (!isRefreshing) {
        //     isRefreshing = true;
        //     try {
        //       await refreshToken();
        //       originalReq.headers['Authorization'] = instance.defaults.headers.common['Authorization'];
        //       return await instance.request(originalReq);
        //     } catch (e) {
        //       return authFailed(error, dispatch);
        //     }
        //   }
        //   return new Promise(resolve => {
        //     subscribeTokenRefresh((token: string) => {
        //       originalReq.headers['Authorization'] = `Bearer ${token}`;
        //       resolve(instance(originalReq));
        //     });
        //   })
        // } else {
        return authFailed(null, dispatch);
        // }
      } else {
        throw error
      }
    }
  )
};
// main
export const AxiosInterceptors = memo(({loginPath = '/login'}: { loginPath?: string }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = getCookies(window.authCookieName_ || '');
    // update token header
    updateDefaultsHeader(token);
    // add token instances
    [api, apiStatic, axios].forEach(item => {
      addSubscribableInstance(item, dispatch);
    });
    // token and source
    api.interceptors.response.use((response) => {
      // update sources
      const {method, url, baseURL}: any = response.config;
      if (method !== 'get') {
        dispatch({
          type: 'SOURCES_UPDATE',
          path: baseURL.length && url.indexOf(baseURL) !== -1 ? url.slice(baseURL?.length) : url
        });
      }
      // update token
      if (method === 'post') {
        if (url === 'Accounts/LoginWithCode') {
          const {token, expires} = response.data;
          updateTokenAll({token, refreshToken: '', expires});
        } else if (url === 'Accounts/Logout') {
          updateTokenAll();
        }
      }
      return response;
    }, error => {
      const {config: {url, method}, response: {status}} = error;
      if (method === 'get' && status === 404 && url === 'Accounts/GetCurrentAppUser') {
        return authFailed(error, dispatch);
      } else {
        throw error;
      }
    });
    // check to canceled static
    apiStatic.interceptors.request.use((config: AxiosRequestConfig) => {
      const key_ = getProcessingKey(config);
      if (key_) {
        if (processing[key_]) processing[key_].cancel(`Cancel request ${key_}`);
        processing[key_] = axios.CancelToken.source();
        config.cancelToken = processing[key_].token;
      }
      return config;
    });
    apiStatic.interceptors.response.use((response) => {
      const key_ = getProcessingKey(response.config);
      if (key_) delete processing[key_];
      return response;
    }, function (error) {
      return Promise.reject(error);
    });
  }, []);

  return null;
});
export default AxiosInterceptors;
