import {put, call, takeLatest} from 'redux-saga/effects'
import * as actions from "../modules/auth"
import {api, requestError} from "../../components/helpers"
import {notifyRequestResult} from "../modules/notify";

function* errorHandler(error: any) {
  const errorMessage = requestError(error);
  yield put(notifyRequestResult(errorMessage, 'error'));
  return errorMessage
}

function* getUser() {
  yield put(actions.authStart());
  try {
    let response = yield call(api.get, 'Accounts/GetCurrentAppUser');
    yield put(actions.authSuccess(response.data));
  } catch (error) {
    yield put(actions.checkLoggedFail());
    return error;
  }
}

function* authUser(action: any) {
  yield put(actions.authStart());
  try {
    const {cookie, ...data} = action.data;
    let response = yield call(api.post, 'Accounts/LoginWithCode', data);
    if (response.status === 200) {
      try {
        const {token, expires} = response.data;
        yield document.cookie = `${window.authCookieName_ || 'test'}=${encodeURIComponent(token)}; secure; samesite=lax; path=/; expires=${new Date(expires).toUTCString()}`;
        yield put(actions.setToken(token));
        yield call(getUser);
      } catch (error) {
        const errorMessage = yield call(errorHandler, error);
        yield put(actions.authFail(errorMessage));
      }
    } else {
      const errorMessage = yield call(errorHandler, response);
      yield put(actions.authFail(errorMessage));
    }
  } catch (error) {
    const errorMessage = yield call(errorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

// @ts-ignore
function* authCode(action: any) {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/GeneratePassword', action.data);
    yield put(actions.authCodeSuccess());
  } catch (error) {
    const errorMessage = yield call(errorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

function* logout() {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/Logout');
    yield document.cookie = `${window.authCookieName_ || 'test'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    yield put(actions.logoutSucceed());
  } catch (error) {
    const errorMessage = requestError(error);
    yield put(notifyRequestResult(errorMessage, 'error'));
    yield put(actions.authFail(errorMessage));
  }
}

export default [
  takeLatest('AUTH_GET_USER', getUser),
  takeLatest('AUTH_USER', authUser),
  takeLatest('AUTH_CODE', authCode),
  takeLatest('AUTH_INITIATE_LOGOUT', logout),
];
