import {lazy} from "react";
import {IRoute} from "AurionCR/components/router";
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LanguageIcon from '@material-ui/icons/Language';

const routes: IRoute[] = [
  {
    path: '/',
    title: 'dashboard',
    // icon: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ "../pages/Dashboard"))
  },
  {
    path: '/crm',
    title: 'crm',
    icon: AssignmentIndIcon,
    permission: 'isAllowToEditCustomers',
    routes: [
      {
        path: '/customers',
        title: 'customers',
        component: lazy(() => import(/* webpackChunkName: 'customers' */ "../pages/Customers")),
      },
      {
        path: '/users',
        title: 'users',
        permission: 'isAllowToEditUsers',
        component: lazy(() => import(/* webpackChunkName: 'users' */ "../pages/Users")),
      },
      {
        path: '/log',
        title: 'log',
        permission: 'isAllowToViewLog',
        component: lazy(() => import(/* webpackChunkName: 'log_crm' */ "../pages/Log_CRM")),
      }
    ]
  },
  {
    path: '/website',
    title: 'website',
    icon: LanguageIcon,
    permission: 'isAllowToEditContent',
    routes: [
      {
        path: '/pages',
        title: 'pages',
        component: lazy(() => import(/* webpackChunkName: 'landing_page' */ "../pages/LandingPage")),
      },
      {
        path: '/website_settings',
        title: 'website-settings',
        permission: 'isAllowToEditSetting',
        component: lazy(() => import(/* webpackChunkName: 'website_settings' */ "../pages/WebsiteSettings")),
      },
      {
        path: '/labels',
        title: 'labels',
        permission: 'isAllowToEditLabels',
        component: lazy(() => import(/* webpackChunkName: 'labels_websites' */ "../pages/Labels_Website")),
      },
      {
        path: '/emails',
        title: 'emails',
        component: lazy(() => import(/* webpackChunkName: 'emails' */ "../pages/Emails")),
      },
      {
        path: '/messages',
        title: 'messages',
        component: lazy(() => import(/* webpackChunkName: 'messages' */ "../pages/Messages")),
      },
    ]
  },
  {
    path: '/tiktak_app',
    title: 'tiktak-app',
    icon: BusinessCenterIcon,
    permission: 'isAllowToEditSetting',
    routes: [
      {
        path: '/log',
        title: 'log',
        permission: 'isAllowToViewLog',
        component: lazy(() => import(/* webpackChunkName: 'log_tiktak_app' */ "../pages/Log")),
      },
      {
        path: '/coupons',
        title: 'coupons',
        component: lazy(() => import(/* webpackChunkName: 'StripeCoupons' */ "../pages/StripeCoupons"))
      },
      {
        path: '/products',
        title: 'products',
        component: lazy(() => import(/* webpackChunkName: 'StripeProducts' */ "../pages/StripeProducts"))
      },
      // {
      //   path: '/bexio_api_debug',
      //   title: 'debug-bexio-api',
      //   permission: 'isAllowToViewDebugBexioAPI',
      //   component: lazy(() => import(/* webpackChunkName: 'bexio_api_debug' */ "../pages/BexioApiDebug")),
      // },
    ]
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: 'isAllowToEditSetting',
    routes: [
      {
        path: '/crm_users',
        title: 'crm-users',
        component: lazy(() => import(/* webpackChunkName: 'CrmUsers' */ "../pages/CrmUsers")),
      },
      // {
      //   path: '/website_settings',
      //   title: 'website-settings',
      //   component: lazy(() => import(/* webpackChunkName: 'website_settings' */ "../pages/WebsiteSettings")),
      // },
      {
        path: '/languages',
        title: 'languages',
        permission: 'isAllowToEditLabels',
        component: lazy(() => import(/* webpackChunkName: 'languages' */ "../pages/Languages")),
      },
      {
        path: '/labels',
        title: 'labels',
        permission: 'isAllowToEditLabels',
        component: lazy(() => import(/* webpackChunkName: 'labels' */ "../pages/Labels")),
      },
      {
        path: '/announcements',
        title: 'announcements',
        component: lazy(() => import(/* webpackChunkName: 'Announcements' */ "../pages/Announcements")),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: 'isAllowToEditPermission',
        component: lazy(() => import(/* webpackChunkName: 'Permissions' */ "../pages/Permissions")),
      },
      // {
      //   path: '/industries',
      //   title: 'industries',
      //   component: lazy(() => import(/* webpackChunkName: 'industries' */ "../pages/Industries")),
      // },
      // {
      //   path: '/company_sizes',
      //   title: 'company-sizes',
      //   component: lazy(() => import(/* webpackChunkName: 'CompanySizes' */ "../pages/CompanySizes")),
      // },
      // {
      //   path: '/bexio_scopes',
      //   title: 'bexio-scopes',
      //   permission: 'isAllowToViewDebugBexioAPI',
      //   component: lazy(() => import(/* webpackChunkName: 'bexio_scopes' */ "../pages/BexioScopes")),
      // },
      // {
      //   path: '/bexio_api_endpoints',
      //   title: 'bexio-api-endpoints',
      //   permission: 'isAllowToViewDebugBexioAPI',
      //   component: lazy(() => import(/* webpackChunkName: 'Bexio_api_end_points' */ "../pages/BexioApiEndPoints")),
      // },
      {
        path: '/links_management',
        title: 'links-management',
        component: lazy(() => import(/* webpackChunkName: 'links_management' */ "../pages/LinksManagement")),
      },
      {
        path: '/time_tracking_views',
        title: 'time-tracking-views',
        component: lazy(() => import(/* webpackChunkName: 'time_tracking_views' */ "../pages/TimeTrackingViews")),
      },
    ]
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ "../pages/Profile")),
  },
  {
    path: '/bexioredirect',
    title: 'bexioredirect',
    inMenu: false,
    inLayout: false,
    private: false,
    component: lazy(() => import(/* webpackChunkName: 'bexioredirect' */ "../pages/BexioRedirect")),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ "../pages/Login"))
  }
];

export default routes;
