import React, {ReactNode} from "react";
import {useDrag, useDrop} from "react-dnd";
import Swap from "./column/templates/swap";
import Column from "./column";
import Controls from "./controls";
import {IDataGridMadel} from "../model";

enum DND_ITEM_TYPES {
  ROW = 'ROW'
}

interface IndexType {
  type: DND_ITEM_TYPES,
  index: number
}

interface GridRowProps {
  uKey: any
  model: IDataGridMadel;
  item: any
  index: number
  scroll: { x: number, y: number, '-x': number };
  onTasks: (tasks: any[]) => void;
  rowControls?: ReactNode;
  isPreview?: boolean
  pagination: any
  setSwap: (p: { startIndex?: number | null, endIndex?: number | null }) => void
  requestSwap: (p: { startIndex: number, endIndex: number }) => void
  isHideSwap: boolean
}

export const GridRow: React.FC<GridRowProps> = ({
                                                  isHideSwap,
                                                  model,
                                                  uKey,
                                                  index,
                                                  item,
                                                  scroll,
                                                  onTasks,
                                                  pagination,
                                                  isPreview,
                                                  rowControls,
                                                  setSwap,
                                                  requestSwap
                                                }) => {
  const dropRef = React.useRef<HTMLTableRowElement>(null)
  const dragRef = React.useRef<HTMLElement>(null)

  const [, drop] = useDrop<IndexType, void, any>({
    accept: DND_ITEM_TYPES.ROW,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset?.y || 0) - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      setSwap({endIndex: index})
    },
    drop: (draggedItem) => {
      const payload = {startIndex: draggedItem.index, endIndex: index}
      requestSwap(payload)
    }
  })

  const [, drag, preview] = useDrag({
    item: {type: DND_ITEM_TYPES.ROW, index},
    begin: () => {
      setSwap({startIndex: index, endIndex: null})
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr
      ref={dropRef}
    >
      {!isHideSwap && !isPreview && model.store.swap && model.store.swap.field && <Swap ref={dragRef} onTasks={onTasks}
                                                                                        pagination={pagination}
                                                                                        scroll={scroll}
                                                                                        rowData={item}
                                                                                        index={index}
      />}
      {model.columns.map(column =>
        <Column
          key={`${uKey}-${column.field}`}
          column={column}
          data={item}
          onTasks={onTasks}
          preview={isPreview}
        />
      )}
      {(!isPreview && (model.controls || rowControls)) && <Controls
        controls={model.controls}
        data={item}
        id={uKey}
        onTasks={onTasks}
        scroll={scroll}
        rowControls={rowControls}
      />}
    </tr>
  )
}
